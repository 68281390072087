import React from "react"

import "../styles/concerts.scss"

export const Concerts = () => {
  const concerts = [
    {
      name: "Lunar Noon Debut Concert",
      link: "https://www.facebook.com/events/696178458427717",
      date: new Date("2022-03-18T20:00:00.000-08:00"),
    },
    {
      name: "Lunar Noon Acoustic Set At RGB",
      link:
        "https://www.eventbrite.com/e/lunar-noon-acoustic-set-tickets-313832771267",
      date: new Date("2022-04-24T15:00:00.000-08:00"),
    },
    {
      name: "Lunar Noon At Zeitgeist",
      link: "https://fb.me/e/1IHj20q4S",
      date: new Date("2022-05-08T18:00:00.000-08:00"),
    },
    {
      name: "Lunar Noon At Milk Bar SF",
      link: "https://www.eventbrite.com/o/the-milk-bar-presents-6806338175",
      date: new Date("2022-06-17T21:30:00.000-08:00"),
    },
  ].sort((concert1, concert2) => +concert2.date - +concert1.date)
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const renderConcert = (
    index: number,
    link: string,
    time: Date,
    name: string
  ) => (
    <li className="concert" key={index}>
      <a href={link}>{name}</a>
      <div>
        {days[time.getDay()]} {months[time.getMonth()]} {time.getDate()}
      </div>
    </li>
  )

  return (
    <section className="Concerts">
      <h3>Concerts</h3>
      {concerts.length > 0 ? (
        concerts.map(({ date, name, link }, index) => {
          const time = new Date(date)
          return <ul>{renderConcert(index, link, time, name)}</ul>
        })
      ) : (
        <div>Nothing scheduled for now!</div>
      )}
    </section>
  )
}
