import { PageProps, Link } from "gatsby"
import React, { useState } from "react"
import { DropdownButton, ButtonGroup, Dropdown } from "react-bootstrap"

import { JamSession } from "../types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GMap from "../components/gMap"
import jamSessions from "../content/jamSessions"

import "../styles/music.scss"
import { Concerts } from "../components/concerts"

const Music = ({ location }: PageProps) => {
  const options = {
    any: "Any Day",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  }

  const [day, setDay] = useState("any")
  const filteredJamSessions = jamSessions.filter(
    (session: JamSession) => day === "any" || session.day === day
  )
  return (
    <Layout location={location}>
      <div className="Music">
        <SEO title="Music" />
        <h1>Music</h1>
        <section>
          <p>
            I grew up in a musical family, with parents interested in jazz and
            classical music. I have two very talented brothers who are also
            musicians, some professionally. I've played an instrument for most
            of my life, and enjoy connecting musically with other humans. I play
            the upright bass, electric bass, and some guitar too.
          </p>
          <p>
            I'm currently part of a band called{" "}
            <a href="https://www.lunar-noon.com/">Lunar Noon</a>. The image for
            this section is us in gingerbread form.
          </p>
        </section>
        <Concerts />
        <section>
          <h3>Jazz jam sessions in SF</h3>
          <i>
            Disclaimer: this content was created pre-covid. Double check info
            with the venue!
          </i>
          <p>
            When I first came to San Francisco, I was curious to find out where
            people played live music, jazz especially. I couldn't find an
            extensive resource with maps, hours and other info. I decided that
            the easiest way was probably to go and play at all these places and
            ask around. That's exactly what I did, and this map is the result.
            Maps like this are hard to keep up to date so if you see something
            new or wrong, head over to the <Link to="/contact">contact</Link>{" "}
            section.
          </p>
          <GMap
            markerData={filteredJamSessions}
            converter={jamSession => ({
              ...jamSession,
              lat: jamSession.lat,
              lng: jamSession.lng,
              title: jamSession.title,
              description: jamSession.description,
            })}
          />
          <div className="filter-container">
            <span>Filter for jam sessions on</span>
            <DropdownButton
              className="time-filter"
              as={ButtonGroup}
              id="day-of-week"
              size="sm"
              variant="secondary"
              title={options[day]}
              onSelect={(key: string) => setDay(key)}
            >
              {Object.keys(options).map(key => (
                <Dropdown.Item eventKey={key} key={key}>
                  {options[key]}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Music
