import { JamSession } from "../types"

const jamSessions: JamSession[] = [
  {
    day: "monday",
    start: "7:30pm",
    end: "10:30pm",
    title: "Savanna Jazz",
    location: "Sheba Lounge",
    description:
      "With the Swingmasons' & Pianist Mike Nourie. Reservations are not required and cover charge is $5 - $10.",
    lat: 37.782915,
    lng: -122.4328083,
    address: "1419 Fillmore St, San Francisco, CA 94115, United States",
    photo:
      "https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sCmRaAAAAqgbonqmPRMBpG6_CLxLb0L-Dfp4FKMCc2gyuaBlA1a1zJq82CwTbjcbR1oTSz-I_82TMbJtITP_PsRaT17CCTKMmAjuHDADvYaCnTgfqIXxSnBkR4oyKJWR091zkI3KoEhBXJnWOC2mgooAd4xQ379e3GhQ49hJgcKRrHphE47IISQ195Z19BA&3u640&5m1&2e1&callback=none&key=AIzaSyDx59VEiT0sN0WV9ytcGVhBzC1oZWJlFM8&token=100320",
  },
  {
    day: "monday",
    start: "8:00pm",
    end: "11:00pm",
    title: "Steve Nelson Trio Jam",
    location: "Sheba Lounge",
    description:
      "Every Monday is Open Mic night at Sheba's. Join the Steve Nelson Trio jam session.  Whether you're an amateur or a professional musician, you're welcome to join the band starting at 8pm.",
    lat: 37.782915,
    lng: -122.4328083,
    address: "1419 Fillmore St, San Francisco, CA 94115, United States",
    photo:
      "https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sCmRaAAAAqgbonqmPRMBpG6_CLxLb0L-Dfp4FKMCc2gyuaBlA1a1zJq82CwTbjcbR1oTSz-I_82TMbJtITP_PsRaT17CCTKMmAjuHDADvYaCnTgfqIXxSnBkR4oyKJWR091zkI3KoEhBXJnWOC2mgooAd4xQ379e3GhQ49hJgcKRrHphE47IISQ195Z19BA&3u640&5m1&2e1&callback=none&key=AIzaSyDx59VEiT0sN0WV9ytcGVhBzC1oZWJlFM8&token=100320",
  },
  {
    day: "monday",
    start: "9:00pm",
    end: "1:00am",
    title: "Who's your Mamma?",
    location: "Tupelo",
    description:
      "Every Monday, every week. Come hear the best in Roots, Soul, R&B, and Funk that the Bay Area has to offer. Hosted by Couti Ma and Young Blake - the nastiest rhythm section this side of S. Dakota.",
    lat: 37.7992817,
    lng: -122.4074927,
    address: "1337 Grant Ave, San Francisco, CA 94133, United States",
    photo:
      "https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sCmRaAAAAs8LpWymJXbcO8R1ntRd8MWkEzIMl_mMfyrz9GBWJUve-jNvZ0XfPt9Ud44V0AUtmnO6Ey67sMXjdN79FIOMwI5fOg8jQG9hTDhlAOZogcNRBzY5FWdYaFfWHH9csVD_VEhDyhUQYz1PtfPVWyA8SM85nGhT9BebGlqJ4Jqo1TY9ZVVW3Hw_NhQ&3u1080&5m1&2e1&callback=none&key=AIzaSyDx59VEiT0sN0WV9ytcGVhBzC1oZWJlFM8&token=89162",
  },
  {
    day: "monday",
    start: "9:30pm",
    end: "1:00am",
    title: "Mike Olmos Presents: The Session.",
    location: "Club Deluxe",
    description:
      "Come if you can play...watch if you can't. It's a great time either way!",
    lat: 37.769772,
    lng: -122.447143,
    address: "1511 Haight St, San Francisco, CA 94117, United States",
    photo:
      "https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sCmRaAAAAbSVndTJIR_UNHgB2ouhj7fI8-v_yh6Jx1MYxvQik4kdhc6m3UustSgcPN8rYJ8WVxSKfy1tUdI09Pz2cF7BXYuBllkjEbLU4nRiN1Tx3ez39yqLhFp4W2imD6_lHtY7REhAlfEx3IWGp-BI2DMwHCtBoGhQCzhKej4b51OhCsqrQIIYaJN1NYQ&3u1000&5m1&2e1&callback=none&key=AIzaSyDx59VEiT0sN0WV9ytcGVhBzC1oZWJlFM8&token=47427",
  },
  {
    day: "tuesday",
    start: "5:00pm",
    end: "8:00am",
    title: "Vince Lateano jam session",
    location: "Pier 23 Cafe",
    description: "",
    lat: 37.8033934,
    lng: -122.4008661,
    address: "23 The Embarcadero, San Francisco, CA 94111, United States",
    photo:
      "https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sCmRaAAAADKwll2OlV8qKyYZSAcPwBF0Gudz2zkiQTDOFYACseKT6KL2g28YcyBA9k8Y5Q8c_4aaQRY1ZZHtlvvV5qEtijxndLvIm_-buV-OiOpCcUZH7siRn5pSBxPq7GyT8ykfEEhCx1WMneU3Pqif96uHX0zfQGhQPAgUKRB6FJlmopGX_yqb62iI1Qw&3u640&5m1&2e1&callback=none&key=AIzaSyDx59VEiT0sN0WV9ytcGVhBzC1oZWJlFM8&token=106718",
  },
  {
    day: "tuesday",
    start: "9:30pm",
    end: "12:30am",
    title: "THE JAM w/ Smith Dobson",
    location: "Club Deluxe",
    description: "",
    lat: 37.769772,
    lng: -122.447143,
    address: "1511 Haight St, San Francisco, CA 94117, United States",
    photo:
      "https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sCmRaAAAAbSVndTJIR_UNHgB2ouhj7fI8-v_yh6Jx1MYxvQik4kdhc6m3UustSgcPN8rYJ8WVxSKfy1tUdI09Pz2cF7BXYuBllkjEbLU4nRiN1Tx3ez39yqLhFp4W2imD6_lHtY7REhAlfEx3IWGp-BI2DMwHCtBoGhQCzhKej4b51OhCsqrQIIYaJN1NYQ&3u1000&5m1&2e1&callback=none&key=AIzaSyDx59VEiT0sN0WV9ytcGVhBzC1oZWJlFM8&token=47427",
  },
  {
    day: "wednesday",
    start: "7:00pm",
    end: "10:00am",
    title: "Noah Frank Jam Session",
    location: "Ocean Ale House",
    description: "Every 1st, 3rd, and 4th Wednesday of the month.",
    lat: 37.7242395,
    lng: -122.4567663,
    address: "-122.4567663",
    photo:
      "https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sCmRaAAAAM6ppSo5JTDDm1NGwfd6tJdtiw1GoDKB4tkDq0Kt7nLpDVPYMoRRaPyAYMv-8_6wc1jsrY5VnVlFcshrJvYxCwEUFDKZddCSNr1qIHe2X2ApbV8Q5YdO1f3aAJQfqFLufEhBn28f2qWYJBo-0AQW8CM4CGhR096S4sY8txgSc5f8dQbjeODuAkQ&3u956&5m1&2e1&callback=none&key=AIzaSyDx59VEiT0sN0WV9ytcGVhBzC1oZWJlFM8&token=45240",
  },
  {
    day: "wednesday",
    start: "7:30pm",
    end: "10:30am",
    title: "Jam session",
    location: "Savanna Jazz",
    description:
      "With Mikel Bee. Reservations are not required and cover charge is $5 - $10.",
    lat: 37.782915,
    lng: -122.4328083,
    address: "1419 Fillmore St, San Francisco, CA 94115, United States",
    photo:
      "https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sCmRaAAAAqgbonqmPRMBpG6_CLxLb0L-Dfp4FKMCc2gyuaBlA1a1zJq82CwTbjcbR1oTSz-I_82TMbJtITP_PsRaT17CCTKMmAjuHDADvYaCnTgfqIXxSnBkR4oyKJWR091zkI3KoEhBXJnWOC2mgooAd4xQ379e3GhQ49hJgcKRrHphE47IISQ195Z19BA&3u640&5m1&2e1&callback=none&key=AIzaSyDx59VEiT0sN0WV9ytcGVhBzC1oZWJlFM8&token=100320",
  },
  {
    day: "wednesday",
    start: "11:00pm",
    end: "2:00am",
    title: "Eric Garland's Late Nite Jam",
    location: "Amnesia",
    description: "",
    lat: 37.7592989,
    lng: -122.4211814,
    address: "853 Valencia St, San Francisco, CA 94110, United States",
    photo:
      "https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sCmRaAAAA5UhBNB_ngftDmeDRAv0tRSnRW6zKCAPEtFT4ojkOzmUGVwqBTwp5gUaWgTNhVT_djtKvcKfGzcagGXEOpfSuMhiy_7yzwy_uIpjFKvPmvNqGnXN1kx8vnfU2UNF-9IC2EhD81Lzy-qHWFGry0ZAG5PDuGhQ6nPp3rDm6u8gHYeCDRmWv6JaJQA&3u4032&5m1&2e1&callback=none&key=AIzaSyDx59VEiT0sN0WV9ytcGVhBzC1oZWJlFM8&token=12438",
  },
  {
    day: "saturday",
    start: "12:00pm",
    end: "4:00pm",
    title: "Hipstermania Jam",
    location: "Revolution Cafe",
    description:
      "Drummer Tim Shea leads up a small group of local musicians and opens up the stage to a jam session after a set.",
    lat: 37.7554775,
    lng: -122.4197749,
    address: "3248 22nd St, San Francisco, CA 94110, United States",
    photo:
      "https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sCmRaAAAAzVNQQs1LKAD2_XWC-N9Y09cGj61ogyz7rVgta6a_p4bY9td6VyZbHaEvnzbu4BXBzs4aar9fI3WPzGD3fy-9ChOYdKBAclvCieIqvWSP2jyK9aHiQBFKcBIFmiSprmbcEhA1-X-doMSyhMYjlR3rIQVLGhS5QZ9jz5go6N8WsfjOreA8nV3Rsg&3u4032&5m1&2e1&callback=none&key=AIzaSyDx59VEiT0sN0WV9ytcGVhBzC1oZWJlFM8&token=35086",
  },
  {
    day: "sunday",
    start: "2:00pm",
    end: "5:00pm",
    title: "Jam session",
    location: "Savanna Jazz",
    description:
      "With drummer Chris Cortez. Reservations are not required and cover charge is $5 - $10.",
    lat: 37.782915,
    lng: -122.4328083,
    address: "1419 Fillmore St, San Francisco, CA 94115, United States",
    photo:
      "https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sCmRaAAAAqgbonqmPRMBpG6_CLxLb0L-Dfp4FKMCc2gyuaBlA1a1zJq82CwTbjcbR1oTSz-I_82TMbJtITP_PsRaT17CCTKMmAjuHDADvYaCnTgfqIXxSnBkR4oyKJWR091zkI3KoEhBXJnWOC2mgooAd4xQ379e3GhQ49hJgcKRrHphE47IISQ195Z19BA&3u640&5m1&2e1&callback=none&key=AIzaSyDx59VEiT0sN0WV9ytcGVhBzC1oZWJlFM8&token=100320",
  },
  {
    day: "sunday",
    start: "4:00pm",
    end: "9:00pm",
    title: "Open Mic with Miles Ahead",
    location: "Cafe International",
    description:
      "Friendly, open jam session that has been running for decades. The stage opens up after an initial set by the house band, Miles Ahead.",
    lat: 37.77217170000001,
    lng: -122.430742,
    address: "508 Haight St, San Francisco, CA 94117, United States",
    photo:
      "https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sCmRaAAAAldR4JAxgLhQY3aIrU3IPMj_V4inL_9FPcUay905RZzQMuxGEe4cn6avzOjXI6Q1ao3kulYwgVV1ulJrchm02UZaHyjXz3ouksSmL3WOAaP2RwxpZvpGqHKAY8a58a6rzEhDLHPwJ7ykGN4kAVZwJLTZ3GhQsQDnHOmbfbnE1-cxHwXJ72eocKA&3u1080&5m1&2e1&callback=none&key=AIzaSyDx59VEiT0sN0WV9ytcGVhBzC1oZWJlFM8&token=16881",
  },
  {
    day: "sunday",
    start: "5:00pm",
    end: "8:00pm",
    title: "Vince Lateano's Dog House Jazz Jam",
    location: "7 Mile House",
    description: `For more than 25 years, acclaimed drummer, Vince Lateano has led his Sunday Jazz Jams in the Bay Area's hottest jazz spots. First started in 1991 at Jazz at Pearl's in North Beach then moved to the Dogpatch Saloon on 3rd St. in 2004, 7 Mile House is Vince's new home, now playfully called the 'Dog House' Jazz Jam.
      With fellow musicians Michael Zisman, Ben Stoloroff and Andrew Speight, the Dog House Jazz Jam lives on with stompin' old school bebop for all ages.`,
    lat: 37.704326,
    lng: -122.4074523,
    address: "2800 Bayshore Blvd, Brisbane, CA 94005, United States",
    photo:
      "https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sCmRaAAAAhQaNUsAWbT9e__rOexFhrc4AG69r1H0Hvo99EV-Wm8xqQpeXOWB9v57jxFYzaOnfe9-MqD4b5vvjNl4wA6ErsbEkWMlvx8OU9oEbVNCI_lPjFdrDUaSrvTTFh9deQ--MEhDPfBcHHUUVbRx846KLsY27GhRUUC3HPaFVszH3LWA7a5XKQ_wfgg&3u2048&5m1&2e1&callback=none&key=AIzaSyDx59VEiT0sN0WV9ytcGVhBzC1oZWJlFM8&token=42396",
  },
  {
    day: "sunday",
    start: "6:00pm",
    end: "8:00pm",
    title: "Javier Santiago Jam session",
    location: "Rockridge Improvement Club",
    description: "",
    lat: 37.8428444,
    lng: -122.2519056,
    address: "5515 College Ave, Oakland, CA 94618, United States",
    photo:
      "https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sCmRaAAAA1ISSx0CvNzNFnaDxoxCUfAY0PeHnEiRxeZgOXDileB9j9zQ9Pl0x96QATfh3FYJtk8NCqgxpECoAHKnEKKwfaIosR4Jdcnylzgn8i2lJ--1sIUMA1CJmPjG-rwI5jn-_EhBpzUtz5nHME02euJx5XCNTGhRpxceJsForJjKU-a2nTryRYgvttA&3u1024&5m1&2e1&callback=none&key=AIzaSyDx59VEiT0sN0WV9ytcGVhBzC1oZWJlFM8&token=42886",
  },
  {
    day: "sunday",
    start: "7:00pm",
    end: "10:00pm",
    title: "The Eulipions Jazz Jam Session",
    location: "Cafe Stritch",
    description:
      "Every Sunday Cafe Stritch hosts our weekly jam session featuring our house band. The house band performs a set from 7PM-8PM, and then the house band leader runs an open jazz jam session. Jazz musicians are welcome to come sign up and sit in. Please arrive early to ensure you get a chance to play. Bringing charts is discouraged, standards are preferred.",
    lat: 37.3306111,
    lng: -121.8860111,
    address: "374 S 1st St, San Jose, CA 95113, United States",
    photo:
      "https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sCmRaAAAArOkmd6Ao2P5mhShvrq-RkEC_sIhQllrf6VGDu40H0r__hDVY5pwDIiCYT2pdkmcoQnHOtZWwqJAH-johhpF3ZyX07x6Ls2w7OXXqwWyfg5x8zH3jhJukEBvdNJjZkEdwEhB7Y44AZOumItvsO_6uwvpFGhS-EvhLijp0IyoCSFDo5EIo8T_tgg&3u4160&5m1&2e1&callback=none&key=AIzaSyDx59VEiT0sN0WV9ytcGVhBzC1oZWJlFM8&token=24336",
  },
  {
    day: "sunday",
    start: "8:30pm",
    end: "10:30pm",
    title: "The People's Session",
    location: "Almond and Oak",
    description:
      "Grammy award winner Tony Peebles leads the People's Session on Sunday nights, when we invite local musicians to join in the open jam. Equal parts jazz, blues, funk & soul.",
    lat: 37.3306111,
    lng: -121.8860111,
    address: "374 S 1st St, San Jose, CA 95113, United States",
    photo:
      "https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sCmRaAAAArOkmd6Ao2P5mhShvrq-RkEC_sIhQllrf6VGDu40H0r__hDVY5pwDIiCYT2pdkmcoQnHOtZWwqJAH-johhpF3ZyX07x6Ls2w7OXXqwWyfg5x8zH3jhJukEBvdNJjZkEdwEhB7Y44AZOumItvsO_6uwvpFGhS-EvhLijp0IyoCSFDo5EIo8T_tgg&3u4160&5m1&2e1&callback=none&key=AIzaSyDx59VEiT0sN0WV9ytcGVhBzC1oZWJlFM8&token=24336",
  },
  {
    day: "sunday",
    start: "9:00pm",
    end: "12:30am",
    title: "Blues/Rock Jam Session",
    location: "Swig",
    description:
      "Our free jam session at Swig Bar in SF enters it's seventh decade as the the hot spot for dancers, local musicians and visiting players to gather every Sunday night. We play the blues and and the hoofers do the West Coast Swing. We start at 9pm and everybody gets to play. Please join us soon!",
    lat: 37.7866667,
    lng: -122.4125,
    address: "561 Geary St, San Francisco, CA 94102, United States",
    photo:
      "https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sCmRaAAAAd2Ag0xObDT5zFUpmY_rOCkaP9b14zCJdsjTPJ7_QlF2EB_7d-kWXyJBAut_IX0uZyVI8CP5plVKFCEg4dz1gW3x9P3TXR2RJNutPL1EXsoAC893owaQlnJzrXLcuxCu9EhDUr1XPIEbwl_uwr_E39l_1GhTfFYqHe8-zLq9EEVXo3bUGptJlfQ&3u413&5m1&2e1&callback=none&key=AIzaSyDx59VEiT0sN0WV9ytcGVhBzC1oZWJlFM8&token=102865",
  },
  {
    day: "sunday",
    start: "9:30pm",
    end: "12:30am",
    title: "SUNDAY SESSIONS",
    location: "Madrone Art Bar",
    description:
      "From cookin' with Blues legend John Lee Hooker to organ summits with B-3 master Dr. Lonnie Smith to television and radio appearances, young Wil Blades is gaining critical acclaim and international recognition. Having performed with legendary artists such as John Lee Hooker and Dr. Lonnie Smith, Melvin Sparks, Idris Muhammad, Joe Louis Walker, Don Braden, Donald Harrison, Karl Denson, Will Bernard, Scott Amendola, Charlie Hunter, Stanton Moore, Betty Joplin, Eddie Marshall, Herbie Lewis, and many others.",
    lat: 37.7742011,
    lng: -122.4373746,
    address: "500 Divisadero St, San Francisco, CA 94117, United States",
    photo:
      "https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sCmRaAAAAU5rkN1TkvwNFQnJsAQsz4POJDjvFG7l48_Dt4qwU8V4SZe7HAof_9p-JLM0PtBGDY7VrRcu5B8uqnCOfGJnd3rX3uSajLANT2Mr-Pr_dss5wvXLpXdLdtOerBWtfBbkEEhDlO4Gq_8YyzC1tWLqwZKcgGhQQl32dzyBVVb4XiNM2gacYx3gspQ&3u6000&5m1&2e1&callback=none&key=AIzaSyDx59VEiT0sN0WV9ytcGVhBzC1oZWJlFM8&token=20517",
  },
]

// this.map = this.createMap()
// new window.google.maps.Marker({
//   position: { lat: 37.7545779, lng: 122.4422725 ,
//   map: this.map,
// })

// const freePlacesFields = [
//   "business_status",
//   "formatted_address",
//   "geometry",
//   "icon",
//   "name",
//   "permanently_closed",
//   "photo",
//   "type",
// ]

// const createPlaceRequest = request =>
//   new Promise((resolve, reject) => {
//     {
//       const service = new google.maps.places.PlacesService(this.map)
//       service.findPlaceFromQuery(request, (results, status) => {
//         console.log(request, status)
//         if (status === google.maps.places.PlacesServiceStatus.OK) {
//           resolve(results[0])
//         } else {
//           reject(results)
//         }
//       })
//     }
//   })

// const hey = jamSessions.map(({ location }) =>
//   createPlaceRequest({ query: location, fields: freePlacesFields })
//     .then(result => {
//       console.log(result)
//       return result
//     })
//     .catch(error => console.error(error, location))
// )
// Promise.all(hey)
//   .then(results => {
//     console.log(results)
//     console.log("lat", results[0].geometry.location.lat())
//     console.log("lng", results[0].geometry.location.lng())
//     console.log("address", results[0].formatted_address)
//     console.log("photo", results[0].photos[0].getUrl())
//   })
//   .catch(error => console.error("error", error))

export default jamSessions
