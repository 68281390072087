import React, { Component } from "react"

import "../styles/gMap.scss"
import { JamSession, extractGeneric } from "../types"

type Props<T = JamSession> = {
  markerData: T[]
  converter: (
    markerData: T
  ) => T & {
    lat: number
    lng: number
    title: string
    description: string
  }
}

export default class GMap extends Component<Props> {
  private mapElement = React.createRef<HTMLDivElement>()
  private map: google.maps.Map<HTMLDivElement>
  private markers = []

  componentDidMount = () => {
    const googleMapScript = document.createElement("script")
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}&libraries=places`
    window.document.body.appendChild(googleMapScript)

    googleMapScript.addEventListener("load", () => {
      this.map = this.createMap()
      this.createMarkers()
    })
  }

  componentDidUpdate({ markerData }) {
    if (markerData !== this.props.markerData) {
      this.clearMarkers()
      this.createMarkers()
    }
  }

  createMap = () =>
    new window.google.maps.Map(this.mapElement.current, {
      zoom: 12,
      center: {
        lat: 37.7545779,
        lng: -122.4422725,
      },
      disableDefaultUI: true,
    })

  createMarker = (markerData: extractGeneric<Props["markerData"]>) => {
    const { lat, lng, title, description } = markerData
    const contentString = `<div class="marker">
      <h6>${title}</h6>
      <p>${description}</p>
    </div>`
    var infowindow = new google.maps.InfoWindow({
      content: contentString,
    })

    var marker = new google.maps.Marker({
      position: { lat, lng },
      map: this.map,
      title,
    })
    this.markers.push(marker)
    marker.addListener("click", () => infowindow.open(this.map, marker))
  }

  clearMarkers() {
    this.markers.forEach(marker => marker.setMap(null))
    this.markers = []
  }

  createMarkers = () => {
    this.props.markerData.forEach(markerData => this.createMarker(markerData))
  }

  render() {
    return <div className="GMap" ref={this.mapElement} />
  }
}
